import {Admin, Resource} from 'react-admin';
import commonConfig from "./config/common";
import authProvider from "./libs/authProvider";
import {AppCreate, AppEdit, AppList} from "./pages/client_version/app";
import httpClient from "./libs/httpClient";
import drfProvider from 'ra-data-django-rest-framework';
import {VersionCreate, VersionEdit, VersionList} from "./pages/client_version/version";
import AndroidIcon from '@material-ui/icons/Android';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
const dataProvider = drfProvider(commonConfig.baseUrl, httpClient);

function App() {
  return (
    <Admin authProvider={authProvider} dataProvider={dataProvider}>
      <Resource icon={AndroidIcon} options={{label: 'App'}} name="client_version/app" list={AppList} edit={AppEdit}
                create={AppCreate}/>
      <Resource icon={AccountTreeIcon} options={{label: 'Version'}} name="client_version/version" list={VersionList} edit={VersionEdit}
                create={VersionCreate}/>
    </Admin>
  );
}

export default App;
