import commonConfig from "../config/common";
import serverApi from "../config/server_api";
import {getToken, removeToken, saveTokens} from "./token";

const authProvider = {
  login: async ({username, password}) => {
    let request = new Request(commonConfig.baseUrl + serverApi.user_login, {
      method: 'post',
      body: JSON.stringify({username, password}),
      headers: new Headers({'Content-Type': 'application/json'}),
    });
    try {
      let response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const auth = await response.json();
      saveTokens(auth.access, auth.refresh);
    } catch {
      throw new Error("Network Error.")
    }
    const token = getToken('access_token');
    request = new Request(commonConfig.baseUrl + serverApi.user_identity, {
      method: 'get',
      headers: new Headers({'Content-Type': 'application/json', 'Authorization': token}),
    })
    try {
      let response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const auth = await response.json()
      localStorage.setItem('auth', JSON.stringify(auth));
    } catch {
      throw new Error("Network Error.")

    }
  },
  logout: () => {
    removeToken();
    localStorage.removeItem('auth')
    return Promise.resolve()
  },
  checkError: (error) => {
    // console.log(error);
    const status = error.status;
    if (status === 401 || status === 403) {
      removeToken();
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: () => {
    return getToken('access_token')
      ? Promise.resolve()
      : Promise.reject();
  },
  getIdentity: () => {
    const auth = localStorage.getItem('auth');
    if (!auth)
      return Promise.reject('No Identity')
    const {id, fullName} = JSON.parse(auth)
    const avatar = null;
    return Promise.resolve({id, fullName, avatar});
  },
  getPermissions: () => {
    return Promise.resolve()
  }
}

export default authProvider;
