import {getToken} from "./token";
import {fetchUtils} from 'react-admin';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  const token = getToken('access_token');
  options.headers.set('Authorization', token);

  return fetchUtils.fetchJson(url, options);

};
export default httpClient;
