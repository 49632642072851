import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  NumberInput
} from 'react-admin';
const validateEdit = (value, allValues) => {
  if (value === allValues.id){
    console.log(value, allValues)
    return "Cannot upgrade version to itself."
  }
  return undefined
}
const VersionFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput source='q' label="Search" alwaysOn/>
    </Filter>
  )
}
export const VersionList = props => {
  return (
    <List filters={<VersionFilter/>} {...props}>
      <Datagrid>
        <TextField source="version_number"/>
        <TextField source="to_upgrade"/>
        <ReferenceField reference='client_version/app' source='app'>
          <TextField source="name"/>
        </ReferenceField>
        <EditButton/>
      </Datagrid>
    </List>
  )
}
const VersionTitle = ({record}) => {
  return <span>Version {record ? `${record.version_number}` : ''}</span>;
}
export const VersionEdit = props => {
return (
  <Edit title={<VersionTitle/>} {...props}>
    <SimpleForm>
      <TextInput source='version_number'/>
      <TextInput source='content' multiple/>
      <TextInput source='url'/>
      <BooleanInput source='to_upgrade'/>
      <BooleanInput source='force_upgrade'/>
      <ReferenceInput reference='client_version/app' source='app'>
        <SelectInput optionText='name'/>
      </ReferenceInput>
      <ReferenceInput reference='client_version/version' source='upgrade_version'>
        <SelectInput validate={[validateEdit]} optionText='version_number'/>
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
}
export const VersionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput source='version_number'/>
      <TextInput source='content' multiple/>
      <TextInput source='url'/>
      <BooleanInput source='to_upgrade'/>
      <BooleanInput source='force_upgrade'/>
      <ReferenceInput reference='client_version/app' source='app'>
        <SelectInput optionText='name'/>
      </ReferenceInput>
      <ReferenceInput reference='client_version/version' source='upgrade_version'>
        <SelectInput optionText='version_number'/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
