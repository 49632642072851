import {Create, Datagrid, Edit, EditButton, Filter, List, SimpleForm, TextField, TextInput} from 'react-admin';

const AppFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput source='q' label="Search" alwaysOn/>
    </Filter>
  )
}
export const AppList = props => {
  return (
    <List filters={<AppFilter/>} {...props}>
      <Datagrid>
        <TextField source="name"/>
        <TextField source="description"/>
        <EditButton/>
      </Datagrid>
    </List>
  )
}
const AppTitle = ({record}) => {
  return <span>App {record ? `${record.name}` : ''}</span>;
}
export const AppEdit = props => {
  return (
    <Edit title={<AppTitle/>} {...props}>
      <SimpleForm>
        <TextInput source='name'/>
        <TextInput source='description' multiline/>
      </SimpleForm>
    </Edit>
  )
}
export const AppCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name"/>
      <TextInput source="description" multiline/>
    </SimpleForm>
  </Create>
)
